import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { LoadingStates, UnityState, UnityStates } from "../constants";
import { UnityInstance } from "../unity";

export interface IUnityContext {
  canvas: HTMLCanvasElement | null;
  setCanvas: (el: HTMLCanvasElement | null) => void;
  instance: UnityInstance | null;
  setInstance: (i: UnityInstance | null) => void;
  state: UnityState;
  setState: (s: UnityState) => void;
  loadProgress: number;
  setLoadProgress: (n: number) => void;

  isLoading: boolean;
  isReady: boolean;
  isPlaying: boolean;
}

export const UnityContext = createContext<IUnityContext>({
  canvas: null,
  setCanvas: () => {},
  instance: null,
  setInstance: () => {},
  state: UnityStates.NotLoaded,
  setState: () => {},
  loadProgress: 0,
  setLoadProgress: () => {},
  isLoading: false,
  isPlaying: false,
  isReady: false,
});

export interface UnityContextProviderProps {
  children?: ReactNode | ReactNode[];
}

export const UnityContextProvider = (props: UnityContextProviderProps) => {
  const { children } = props;

  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
  const [instance, setInstance] = useState<UnityInstance | null>(null);
  const [state, setState] = useState<UnityState>(UnityStates.NotLoaded);

  const [loadProgress, setLoadProgress] = useState(0);

  const handleSetState = useCallback(
    (s: UnityState) => {
      console.log("State updated:", s);
      setState(s);
    },
    [setState],
  );

  useEffect(() => {
    if (instance) {
      return () => {
        console.log("Replacing Unity instance");
        instance.Quit().then(() => {
          console.log("Previous Unity instance closed");
        });
      };
    }
  }, [instance]);

  return (
    <UnityContext.Provider
      value={{
        canvas,
        setCanvas,
        instance,
        setInstance,
        state,
        setState: handleSetState,
        loadProgress,
        setLoadProgress,
        isLoading: LoadingStates.includes(state),
        isReady: state === UnityStates.Ready,
        isPlaying: state === UnityStates.Playing,
      }}
    >
      {children}
    </UnityContext.Provider>
  );
};

export default UnityContextProvider;
