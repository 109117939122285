import { Instagram, YouTube } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React from "react";
import DGRLogo from "../assets/images/dgr-logo.svg";
import ThankYou from "../assets/images/thank-you.png";
import ThumbsUp from "../assets/images/thumbs-up.png";
import MetzelerLogo from "../assets/images/logo_metzeler-blue.svg";
import DgrTheme from "../themes/DgrTheme";
import { colours } from "../utilities/constants";

export default function DGRMobilePage() {
  return (
    <ThemeProvider theme={DgrTheme}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"stretch"}
        minHeight={"100svh"}
        paddingBottom={10}
        sx={{
          backgroundColor: "#d5d0ba",
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"flex-start"}
          padding={1}
        >
          <Box
            component={"img"}
            src={ThumbsUp}
            sx={{
              width: {
                xs: "35%",
                sm: 200,
                md: 275,
                lg: 275,
                xl: 350,
              },
            }}
          />
          <Box
            display={"flex"}
            component={"img"}
            src={ThankYou}
            sx={{
              width: {
                xs: "60%",
                sm: 355,
                md: 537,
                lg: 537,
                xl: 658,
              },
            }}
          />
        </Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          marginTop={{ xs: -3, md: -2, lg: 0 }}
          paddingX={3}
          spacing={{ xs: 2, md: 5, lg: 8 }}
        >
          <Box
            component={"img"}
            src={MetzelerLogo}
            sx={{
              width: {
                xs: 180,
                sm: 230,
                md: 275,
                lg: 300,
                xl: 350,
              },
            }}
          />
          <Box
            component={"img"}
            src={DGRLogo}
            sx={{
              width: {
                xs: 80,
                sm: 100,
                md: 140,
                lg: 175,
                xl: 220,
              },
            }}
          />
        </Stack>
        <Typography
          variant={"h1"}
          marginTop={{ xs: 2, md: 3, lg: 5 }}
          marginBottom={{ xs: 3, md: 5, lg: 6 }}
          paddingX={4}
        >
          Greetings Fine Ladies & Gentleman of DGR,
        </Typography>
        <Stack
          direction={"column"}
          alignItems={"center"}
          paddingX={4}
          spacing={2}
        >
          <Typography variant={"body1"}>
            Thank you for your support & passion in the ride across Australia!
          </Typography>
          <Typography variant={"body1"}>
            Visit this web link via computer to explore the Metzeler Virtual
            World, featuring a brand new DGR ‘24 section going live soon.
          </Typography>
          <Typography variant={"body1"}>
            To cement your spot alongside other participating two-wheel legends,
            post your memories captured during DGR ‘24 to Instagram, using the
            hashtags
          </Typography>
          <Typography variant={"caption"}>
            #gentlemansride2024 #ridewithmetzeler
          </Typography>
          <Stack>
            <Typography variant={"subtitle1"}>Check out the new</Typography>
            <Typography variant={"h2"}>Metzeler Roadtec 02 Tyres</Typography>
          </Stack>
          <Box
            component={"iframe"}
            width={{
              xs: "70vw",
              sm: 500,
              md: 600,
              lg: 700,
              xl: 900,
            }}
            height={{
              xs: "39.375vw",
              sm: 281.25,
              md: 337.5,
              lg: 393.75,
              xl: 506.25,
            }}
            src="https://www.youtube.com/embed/L9OP7pCnqQ8?si=KLDEI35fuJIny7UW"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            paddingX={2}
          />
          <Stack direction={"column"} alignItems={"center"}>
            <Typography variant={"h2"}>Follow Metzeler on Socials</Typography>
            <Stack direction={"row"} justifyContent={"center"}>
              <IconButton
                href={"https://www.instagram.com/metzelermoto/"}
                sx={{
                  color: colours.black,
                }}
              >
                <Instagram fontSize={"large"} />
              </IconButton>
              <IconButton
                href={
                  "https://www.youtube.com/channel/UCAtpayCGVZH9wlIpM8qsGGg"
                }
                sx={{
                  color: colours.black,
                }}
              >
                <YouTube fontSize={"large"} />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </ThemeProvider>
  );
}
