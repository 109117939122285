import { InfoOutlined } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import Logo from "../assets/images/logo_metzeler.svg";
import { colours } from "../utilities/constants";

const MobilePage = (): JSX.Element => {
  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      sx={{
        height: "100vh",
        width: "100vw",
        paddingTop: 8,
        paddingBottom: 11,
        paddingX: 3,
        backgroundColor: colours.primary,
      }}
    >
      <Stack
        flexGrow={1}
        spacing={2}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Stack
          alignItems={"center"}
          sx={{
            paddingX: 2,
          }}
        >
          <InfoOutlined sx={{ fontSize: "2.5rem" }} />
          <Typography
            variant={"h1"}
            sx={{ textAlign: "center", fontSize: "1.75rem" }}
          >
            Mobile viewing not supported
          </Typography>
        </Stack>
        <Typography
          variant={"body2"}
          sx={{ textAlign: "center", fontSize: "1rem" }}
        >
          This Virtual Showroom is a desktop experience only. Visit
          www.metzelermotorcycletyres.com on your desktop computer to view.
        </Typography>
      </Stack>
      <Box component={"img"} src={Logo} />
    </Stack>
  );
};

export default MobilePage;
