import { createTheme } from "@mui/material";
import { colours } from "../utilities/constants";
import "./Univers.css";

const dgrTheme = createTheme({
  typography: {
    allVariants: {
      fontFamily: ["Univers", "Arial", "sans-serif"].join(","),
      color: colours.black,
      textAlign: "center",
    },
    h1: {
      fontFamily: ["Univers Condensed Bold", "Arial", "sans-serif"].join(","),
      fontWeight: 700,
      fontSize: "2rem",
    },
    h2: {
      fontFamily: ["Univers Condensed", "Arial", "sans-serif"].join(","),
      fontWeight: 700,
      fontSize: "1.5rem",
      textTransform: "uppercase",
    },
    subtitle1: {
      fontFamily: ["Univers Light", "Arial", "sans-serif"].join(","),
      fontWeight: 400,
      fontSize: "1.5rem",
      textTransform: "uppercase",
    },
    body1: {
      fontWeight: 500,
      fontSize: "1.25rem",
      lineHeight: "1.25rem",
    },
    caption: {
      fontFamily: ["Univers Light", "Arial", "sans-serif"].join(","),
      fontWeight: 600,
      fontSize: "0.875rem",
    },
  },
});

export default dgrTheme;
