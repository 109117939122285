import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import Progress from "./Progress";

export interface PageControlsProps {
  index: number;
  pages: string[];
  onChange: (value: number | ((i: number) => number)) => void;
}

export default function PageControls(props: PageControlsProps) {
  const { index, onChange, pages } = props;

  return (
    <Stack
      direction={"row"}
      justifyContent={"stretch"}
      alignItems={"flex-end"}
      spacing={4}
    >
      <Progress percent={index / (pages.length - 1)} sx={{ flex: 1 }} />
      <Stack
        direction={"row"}
        justifyContent={"stretch"}
        alignItems={"center"}
        spacing={1.5}
      >
        <Typography variant={"body1"}>
          {index + 1} / {pages.length}
        </Typography>
        <Button
          variant={"contained"}
          onClick={() => onChange((i) => Math.max(i - 1, 0))}
          sx={{
            minWidth: 0,
            padding: 0.5,
            transform: "skew(-15deg)",
          }}
        >
          <ChevronLeft sx={{ fontSize: "1.5rem" }} />
        </Button>
        <Button
          variant={"contained"}
          onClick={() => onChange((i) => Math.min(i + 1, pages.length - 1))}
          sx={{
            minWidth: 0,
            padding: 0.5,
            transform: "skew(-15deg)",
          }}
        >
          <ChevronRight sx={{ fontSize: "1.5rem" }} />
        </Button>
      </Stack>
    </Stack>
  );
}
