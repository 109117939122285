import { UnityEvent } from "./index";
import UnityEventBase from "./UnityEventBase";

export const PageOpenEventType = "PageOpen";

export interface PageOpenEvent {
  id: string;
}

export function createPageOpenEvent(id: string): UnityEventBase<PageOpenEvent> {
  return {
    type: PageOpenEventType,
    data: {
      id,
    },
  };
}

export function isPageOpenEvent(
  event: UnityEvent
): event is UnityEventBase<PageOpenEvent> {
  return event.type === PageOpenEventType;
}
