import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AnalyticsWrapper from "./pages/AnalyticsWrapper";
import App from "./pages/App";
import DGRMobilePage from "./pages/DGRMobilePage";
const router = createBrowserRouter(
  [
    {
      element: <AnalyticsWrapper />,
      children: [
        {
          index: true,
          element: <App />,
        },
        { path: "dgr", element: <DGRMobilePage /> },
      ],
    },
  ],
  {
    future: {
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_relativeSplatPath: true,
    },
  }
);

export default function Router() {
  return (
    <RouterProvider router={router} future={{ v7_startTransition: true }} />
  );
}
