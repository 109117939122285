import React, { ReactNode } from "react";
import { Outlet } from "react-router-dom";
import useGoogleTagTracker from "../utilities/hooks/useGoogleTagTracker";

export interface AnalyticsWrapperProps {
  children?: ReactNode;
}

export default function AnalyticsWrapper() {
  useGoogleTagTracker();

  return <Outlet />;
}
