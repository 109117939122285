import { Box, Dialog, DialogContent } from "@mui/material";
import React from "react";
import { IVideoContext } from "./VideoContextProvider";

export interface VideoOverlayProps {
  ctx: IVideoContext;
}

export default function VideoOverlay(props: VideoOverlayProps) {
  const { ctx } = props;
  const { videoSrc, setVideoSrc } = ctx;

  return (
    <Dialog
      open={!!videoSrc}
      onClose={() => setVideoSrc(undefined)}
      maxWidth={"xl"}
    >
      <DialogContent>
        <Box
          component={"video"}
          controls
          sx={{
            aspectRatio: "16/9",
          }}
        >
          <source src={`/videos/${videoSrc}`} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
