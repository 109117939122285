import { createTheme } from "@mui/material";
import { colours } from "../utilities/constants";
import "./Univers.css";

const mainTheme = createTheme({
  typography: {
    allVariants: {
      fontFamily: ["Univers Condensed", "Arial", "sans-serif"].join(","),
      color: colours.white,
    },
    h1: {
      fontSize: "3.5rem",
      fontWeight: 700,
      textTransform: "uppercase",
    },
    h2: {
      fontSize: "3rem",
      fontWeight: 200,
      textTransform: "uppercase",
    },
    h3: {
      fontSize: "2rem",
      fontWeight: 300,
      textTransform: "uppercase",
    },
    body2: {
      fontFamily: ["Arial", "sans-serif"].join(","),
      textAlign: "center",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          borderRadius: 0,
          backgroundColor: colours.white,
          color: colours.primary,
          fontSize: "1.25rem",
          "&:hover": {
            backgroundColor: colours.secondary,
          },
          "&:disabled": {
            backgroundColor: colours.primary,
            color: colours.white,
          },
        },
      },
    },
  },
});

export default mainTheme;
