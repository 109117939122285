import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { createContentInteractionEvent } from "../plugins/analytics/events/ContentInteractionEvent";
import useAnalyticsDispatch from "../plugins/analytics/useAnalyticsDispatch";
import useUnauthenticatedUser from "../plugins/analytics/useUnauthenticatedUser";
import ExperiencePage from "./ExperiencePage";
import MobilePage from "./MobilePage";

function App() {
  const user = useUnauthenticatedUser();
  const dispatch = useAnalyticsDispatch();

  useEffect(() => {
    dispatch(createContentInteractionEvent(user, "visit", "zone", "zone"));
  }, [dispatch, user]);

  return isMobile ? <MobilePage /> : <ExperiencePage />;
}

export default App;
