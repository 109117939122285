import { useEffect } from "react";
import { UnityEventType } from "../constants";
import { SerializedUnityEvent, UnityEvent } from "../events";
import useUnityCanvas from "./useUnityCanvas";

export default function useUnityEventHandler(
  handler: (event: UnityEvent) => void
) {
  const canvas = useUnityCanvas();

  useEffect(() => {
    const handleEvent = (event: CustomEvent<SerializedUnityEvent>) => {
      const parsed = {
        type: event.detail.type,
        data: JSON.parse(event.detail.data),
      };

      // console.log("Received unity event:", parsed);

      handler(parsed);
    };

    if (canvas) {
      canvas?.addEventListener(UnityEventType, handleEvent, false);
    }

    return () => {
      if (canvas) {
        canvas?.removeEventListener(UnityEventType, handleEvent);
      }
    };
  });
}
