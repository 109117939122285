import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function useGoogleTagTracker() {
  const location = useLocation();
  const [prevPath, setPrevPath] = useState("");

  useEffect(() => {
    if (location.pathname !== prevPath) {
      setPrevPath(location.pathname);

      // Google Tag manager tracking
      // @ts-ignore
      gtag("event", "page_view", {});
    }
  }, [location.pathname, prevPath]);
}
