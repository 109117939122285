import { v4 as uuidv4 } from "uuid";
import { createSessionEvent } from "./events/SessionEvent";
import useAnalyticsDispatch from "./useAnalyticsDispatch";

let session_id: string | null = null;

export default function useSessionId() {
  const dispatch = useAnalyticsDispatch();

  if (!session_id) {
    session_id = uuidv4();
    const user_id = localStorage.getItem("user_id");
    dispatch(createSessionEvent(user_id, session_id));

    console.log("Session start:", {
      user_id,
      session_id,
    });
  }

  return session_id;
}
