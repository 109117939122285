import { Box, BoxProps, Tooltip } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { combineSxProps } from "si-plugin-utils";
import { UnityStates } from "../constants";
import { isCursorEvent } from "../events";
import useUnityEventHandler from "../hooks/useUnityEventHandler";
import { UnityInstance } from "../unity";
import { UnityContext } from "./UnityContextProvider";

const defaultBuildUrl =
  process.env.REACT_APP_UNITY_DEFAULT_BUILD ?? "Build/LocalServer";
const defaultStreamingAssetUrl =
  process.env.REACT_APP_UNITY_DEFAULT_STREAMING_ASSETS ?? "StreamingAssets";
const company = process.env.REACT_APP_UNITY_COMPANY;
const product = process.env.REACT_APP_UNITY_PRODUCT;
const version = process.env.REACT_APP_UNITY_VERSION;

export interface UnityCanvasProps
  extends Omit<BoxProps<"canvas">, "component"> {
  buildUrl?: string;
  streamingAssetsUrl?: string;
  onLoadProgress?: (completed: number) => void;
  onLoadComplete?: (instance: UnityInstance) => void;
}

/**
 * This is the raw unity experience and canvas, with no layout or loading screen
 */
const UnityCanvas = (props: UnityCanvasProps): JSX.Element => {
  const {
    buildUrl = defaultBuildUrl,
    streamingAssetsUrl = defaultStreamingAssetUrl,
    onLoadProgress,
    onLoadComplete,
    sx,
    ...others
  } = props;

  const { canvas, setCanvas, state, setState, setLoadProgress, setInstance } =
    useContext(UnityContext);

  const [tooltip, setTooltip] = useState<string>();

  // Load the Unity loader script
  useEffect(() => {
    if (buildUrl) {
      console.log("Loading Script");
      setState(UnityStates.ScriptLoading);

      const eventListener = () => {
        console.log("Script Loaded");
        setState(UnityStates.ScriptReady);
      };
      const loaderUrl = `${buildUrl}.loader.js`;

      // Gotta cast this because the AFrame types pollute the document.createElement definition
      const script = document.createElement(
        "script"
      ) as unknown as HTMLScriptElement;
      script.src = loaderUrl;
      script.addEventListener("load", eventListener);
      document.body.appendChild(script);

      return () => {
        console.log("Unloading Script");
        script.removeEventListener("load", eventListener);
        script.remove();
        setState(UnityStates.NotLoaded);
        setInstance(null);
      };
    }
  }, [buildUrl, setState, setInstance]);

  // Create the unity instance
  useEffect(() => {
    const handleLoadProgress = (p: number) => {
      if (onLoadProgress) {
        onLoadProgress(p);
      }
      setLoadProgress(p);
    };

    if (canvas && state === UnityStates.ScriptReady) {
      console.log("Loading Unity Experience");
      setState(UnityStates.Loading);

      const config = {
        dataUrl: `${buildUrl}.data`,
        frameworkUrl: `${buildUrl}.framework.js`,
        codeUrl: `${buildUrl}.wasm`,
        streamingAssetsUrl,
        companyName: company,
        productName: product,
        productVersion: version,
      };

      createUnityInstance(canvas, config, handleLoadProgress)
        .then((instance) => {
          console.log("Unity load complete");
          setState(UnityStates.Ready);
          setInstance(instance);
          if (onLoadComplete) {
            onLoadComplete(instance);
          }
          return instance;
        })
        .catch((err: any) => {
          console.log(`Unity load failed for build path [${buildUrl}]`, err);
          return null;
        });
    }
  }, [
    canvas,
    buildUrl,
    onLoadComplete,
    onLoadProgress,
    setLoadProgress,
    setInstance,
    streamingAssetsUrl,
    state,
    setState,
  ]);

  useUnityEventHandler(
    useCallback(
      (event) => {
        if (isCursorEvent(event)) {
          // Update cursor type and tooltip text
          setTooltip(event.data.tooltip);
          if (canvas) {
            // has to go on element style or it is overridden by unity set default.
            canvas.style.cursor = event.data.cursorType;
          }
        }
      },
      [canvas, setTooltip]
    )
  );

  return (
    <Tooltip followCursor placement="top" title={tooltip}>
      <Box
        id={"unity-canvas"}
        component={"canvas"}
        ref={setCanvas}
        tabIndex={1}
        sx={combineSxProps(
          {
            flex: "auto",
            height: "100%",
            display: "block",
          },
          sx
        )}
        {...others}
      />
    </Tooltip>
  );
};

export default UnityCanvas;
