import { useCallback } from "react";
import { UnityEventReceiver, UnityService } from "../constants";
import { isUnityEvent, serializeEvent } from "../events";
import useUnity from "./useUnity";

export default function useUnityDispatch() {
  const instance = useUnity();

  return useCallback(
    (
      value: object | string | number | null | undefined,
      method: string = UnityEventReceiver,
      target: string = UnityService
    ) => {
      if (instance) {
        if (typeof value === "object") {
          if (isUnityEvent(value)) {
            value = JSON.stringify(serializeEvent(value));
          } else {
            value = JSON.stringify(value);
          }
        }

        console.log(`Sending Event to Unity: ${target}.${method}(${value})`);

        instance.SendMessage(target, method, value);
      }
    },
    [instance]
  );
}
