import { Close } from "@mui/icons-material";
import { Box, Dialog, IconButton, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { parse } from "yaml";

import Logo from "../assets/images/logo_metzeler.svg";
import { createContentInteractionEvent } from "../plugins/analytics/events/ContentInteractionEvent";
import useAnalyticsDispatch from "../plugins/analytics/useAnalyticsDispatch";
import useAnalyticsTimer from "../plugins/analytics/useAnalyticsTimer";
import useUnauthenticatedUser from "../plugins/analytics/useUnauthenticatedUser";
import { colours } from "../utilities/constants";
import PageControls from "./PageControls";

export interface ContentOverlayProps {
  id: string | null;
  onClose?: () => void;
}

interface ContentGroup {
  title: string;
  pages: ContentPage[];
}

interface ContentPage {
  title: string;
  filename: string;
  type: "image" | "video";
}

export default function ContentOverlay(props: ContentOverlayProps) {
  const { id, onClose } = props;

  const dispatch = useAnalyticsDispatch();
  const user = useUnauthenticatedUser();

  const [index, setIndex] = useState(0);

  const { data: config } = useQuery<ContentGroup>({
    queryKey: ["content", id],
    queryFn: () =>
      fetch(`./${id}/config.yml`)
        .then((resp) => {
          if (!resp.ok) {
            throw new Error(`Network response was ${resp.status}`);
          }
          return resp.text();
        })
        .then((text) => {
          // console.log(`Config File loaded:\n${text}`);

          return parse(text);
        }),
    enabled: !!id,
  });

  const page = config?.pages?.[index] ?? null;

  useAnalyticsTimer("chapter", id);
  useAnalyticsTimer("page", page && `${id}-${page.title}`);

  useEffect(() => {
    if (!id) {
      setIndex(0);
    }
  }, [id, setIndex]);

  useEffect(() => {
    if (id) {
      dispatch(createContentInteractionEvent(user, "chapter", id, id));
    }
  }, [dispatch, user, id]);

  useEffect(() => {
    if (id && page) {
      dispatch(createContentInteractionEvent(user, "page", page.title, id));
    }
  }, [dispatch, user, id, page]);

  return (
    <Dialog
      open={!!id}
      onClose={onClose}
      maxWidth={false}
      PaperProps={{
        elevation: 0,
        sx: {
          backgroundColor: colours.primary,
          transition: "height 0.8s",
        },
      }}
    >
      <Stack
        direction={{
          lg: "row",
          xs: "column",
        }}
        alignItems={"stretch"}
        justifyContent={"space-between"}
        overflow={"auto"}
        flex={1}
        spacing={6}
        padding={3}
        maxWidth={"100%"}
        maxHeight={{
          lg: "100%",
        }}
        width={{
          xl: 1400,
          lg: 1100,
          md: 800,
          sm: 500,
          xs: 500,
        }}
      >
        <Stack
          direction={"column"}
          alignItems={"stretch"}
          justifyContent={"flex-start"}
          flex={"0 0 auto"}
          width={{
            xl: 550,
            lg: 300,
            xs: "100%",
          }}
        >
          <Box
            component={"img"}
            src={Logo}
            alt={"Metzeler logo"}
            width={{
              xs: 200,
            }}
          ></Box>
          <Stack
            direction={"column"}
            alignItems={"stretch"}
            justifyContent={"center"}
            flex={1}
          >
            {config?.title && (
              <Typography variant={"h3"}>{config?.title}</Typography>
            )}
            {page?.title && (
              <Typography variant={"h2"}>{page?.title}</Typography>
            )}

            {(config?.pages.length ?? 0) > 1 && (
              <PageControls
                index={index}
                onChange={setIndex}
                pages={config?.pages.map((p) => p.filename) ?? []}
              />
            )}
          </Stack>
        </Stack>
        <Box
          sx={{
            display: "flex",
            flexFlow: "column",
            alignItems: "stretch",
            justifyContent: "stretch",
            flex: "1 1 0",
          }}
        >
          {page?.type === "image" && (
            <Box
              component={"img"}
              src={`/${id}/${page?.filename}`}
              sx={{
                flex: 1,
                width: 1,
                height: 1,
                objectFit: "contain",
                display: "block",
              }}
            />
          )}
          {page?.type === "video" && (
            <Box
              component={"video"}
              controls
              autoPlay
              sx={{
                flex: 1,
                width: 1,
                height: 1,
                objectFit: "contain",
                display: "block",
              }}
            >
              <source src={`/${id}/${page?.filename}`} />
            </Box>
          )}
        </Box>
      </Stack>
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
        }}
      >
        <Close sx={{ fontSize: "2.5rem", color: "#FFFFFF" }} />
      </IconButton>
    </Dialog>
  );
}
