import { TwoWheeler } from "@mui/icons-material";
import { Box, SxProps } from "@mui/material";
import React from "react";
import { combineSxProps } from "si-plugin-utils";
import { colours } from "../utilities/constants";

export interface ProgressProps {
  percent: number;
  sx?: SxProps;
}

export default function Progress(props: ProgressProps) {
  const { percent, sx } = props;

  return (
    <Box
      sx={combineSxProps(
        {
          borderStyle: "solid",
          borderColor: colours.white,
          borderWidth: 0,
          borderBottomWidth: 1,
          height: 30,
          position: "relative",
        },
        sx
      )}
    >
      <TwoWheeler
        sx={{
          position: "absolute",
          top: 6,
          left: `calc(calc(100% - 24px) * ${percent})`,
          transition: "left 0.8s linear",
          color: colours.white,
        }}
      />
    </Box>
  );
}
