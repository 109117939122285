import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from "react";

export interface IVideoContext {
  videoSrc: string | undefined;
  setVideoSrc: Dispatch<SetStateAction<string | undefined>>;
}

export const VideoContext = createContext<IVideoContext>({
  videoSrc: undefined,
  setVideoSrc: () => {},
});

export interface VideoContextProviderProps {
  overlay: (context: IVideoContext) => ReactNode;
  children: ReactNode;
}

export const VideoContextProvider = (props: VideoContextProviderProps) => {
  const { overlay, children } = props;
  const [src, setSrc] = useState<string>();

  const value = useMemo<IVideoContext>(
    () => ({
      videoSrc: src,
      setVideoSrc: setSrc,
    }),
    [src, setSrc]
  );

  return (
    <VideoContext.Provider value={value}>
      {overlay(value)}
      {children}
    </VideoContext.Provider>
  );
};
