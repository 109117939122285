import { Box, SxProps, Theme } from "@mui/material";
import React from "react";
import { combineSxProps } from "si-plugin-utils";

export interface UnityLoadScreenProps {
  loadProgress: number;
  onClick?: () => void;
  sx?: SxProps<Theme>;
}

const UnityLoadScreen = (props: UnityLoadScreenProps): JSX.Element => {
  const { loadProgress, onClick, sx } = props;

  return (
    <Box
      component={"button"}
      onClick={onClick}
      sx={combineSxProps(
        {
          position: "absolute",
          inset: 0,
          display: "flex",
          flexFlow: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#FFFFFF",
        },
        sx,
      )}
    >
      <span>Loading</span>
      <Box
        sx={{
          width: 265,
          height: 35,
          padding: 0.5,
          backgroundColor: "#666666",
        }}
      >
        <Box
          sx={{
            width: `${Math.floor(loadProgress * 100)}%`,
            height: "100%",
            backgroundColor: "#ec1a1a",
          }}
        />
      </Box>
    </Box>
  );
};

export default UnityLoadScreen;
