import {
  MusicNote,
  MusicOff,
  QuestionMark,
  VolumeMute,
  VolumeUp,
} from "@mui/icons-material";
import { Box, Button, IconButton, Slider, Stack, Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";
import { useUnityDispatch, useUnityEventHandler } from "si-plugin-unity";
import UnityPlayer from "si-plugin-unity/components/UnityPlayer";
import { isPageOpenEvent } from "si-plugin-unity/events/PageOpenEvent";
import { isVideoPlayEvent } from "si-plugin-unity/events/VideoPlayEvent";
import {
  createViewpointEvent,
  isViewpointEvent,
} from "si-plugin-unity/events/ViewpointEvent";
import { createVolumeEvent } from "si-plugin-unity/events/VolumeEvent";
import ContentOverlay from "../components/ContentOverlay";
import InstructionsOverlay from "../components/InstructionsOverlay";
import LoadScreen from "../components/LoadScreen";
import { VideoContext } from "../components/VideoContextProvider";
import useAnalyticsTimer from "../plugins/analytics/useAnalyticsTimer";
import { colours } from "../utilities/constants";

const buildUrl = "Unity/Build";

export default function ExperiencePage() {
  const { setVideoSrc } = useContext(VideoContext);
  const dispatch = useUnityDispatch();

  const [loadScreen, setLoadScreen] = useState(true);
  const [instructions, setInstructions] = useState(false);
  const [pageId, setPageId] = useState<string | null>(null);
  const [viewpointId, setViewpointId] = useState<string | null>(null);
  const [showVolume, setShowVolume] = useState(false);
  const [volume, setVolume] = useState(50);

  const loadTimer = useAnalyticsTimer("loading", "zone1");
  const zoneTimer = useAnalyticsTimer("zone", "zone1");

  useUnityEventHandler((event) => {
    if (isVideoPlayEvent(event)) {
      setVideoSrc(event.data.src);
    } else if (isPageOpenEvent(event)) {
      setPageId(event.data.id);
    } else if (isViewpointEvent(event)) {
      if (event.data.active) {
        setViewpointId(event.data.id);
      } else if (event.data.id === viewpointId) {
        setViewpointId(null);
      }
    }
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexFlow: "column",
        justifyContent: "stretch",
        alignItems: "stretch",
        position: "relative",
        height: "100vh",
      }}
    >
      <UnityPlayer
        buildUrl={buildUrl}
        loadScreen={(p) => {
          if (p === 1) {
            loadTimer.StopAndSend();
          }

          return (
            <LoadScreen
              progress={p}
              onClick={() => {
                if (p === 1) {
                  zoneTimer.Restart();
                  setLoadScreen(false);
                }
              }}
            />
          );
        }}
        forceShowLoadScreen={loadScreen}
      />
      <Stack
        spacing={1}
        alignItems={"flex-end"}
        sx={{
          position: "absolute",
          top: 8,
          right: 16,
        }}
      >
        <Tooltip title={"Help"} placement={"left"}>
          <IconButton
            onClick={() => {
              setInstructions(true);
            }}
          >
            <QuestionMark
              sx={{
                color: colours.white,
              }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={
            <Stack>
              <span>
                Song: Hip Jazz by <em>Benjamin Tissot</em>
              </span>
              <span>
                Music by https://www.bensound.com/free-music-for-videos
              </span>
              <span>License code: FZUY9HRHPGRRS0XT</span>
            </Stack>
          }
          placement={"left"}
        >
          <IconButton
            onClick={() => {
              setShowVolume(!showVolume);
            }}
          >
            {volume > 0 ? (
              <MusicNote
                sx={{
                  color: colours.white,
                }}
              />
            ) : (
              <MusicOff
                sx={{
                  color: colours.white,
                }}
              />
            )}
          </IconButton>
        </Tooltip>
        {showVolume && (
          <Stack direction={"row"} spacing={1.5} alignItems={"center"}>
            <VolumeMute
              sx={{
                color: colours.white,
              }}
            />
            <Slider
              value={volume}
              onChange={(e, value) => {
                const val = Array.isArray(value) ? value[0] : value;
                setVolume(val);
                dispatch(createVolumeEvent(val / 100));
              }}
              sx={{
                minWidth: 100,
              }}
            />
            <VolumeUp
              sx={{
                color: colours.white,
              }}
            />
          </Stack>
        )}
      </Stack>
      <ContentOverlay
        id={pageId}
        onClose={() => {
          setPageId(null);
        }}
      />
      <InstructionsOverlay
        open={instructions}
        onClose={() => {
          setInstructions(false);
        }}
      />
      {viewpointId && (
        <Button
          variant={"contained"}
          sx={{
            position: "absolute",
            bottom: 20,
            right: 20,
            minWidth: 200,
          }}
          onClick={() => {
            dispatch(createViewpointEvent(viewpointId, false));
            setViewpointId(null);
          }}
        >
          Stand Up
        </Button>
      )}
    </Box>
  );
}
