import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import ReactDOM from "react-dom/client";
import { UnityContextProvider } from "si-plugin-unity";
import { VideoContextProvider } from "./components/VideoContextProvider";
import VideoOverlay from "./components/VideoOverlay";
import CustomTheme from "./themes/MainTheme";
import "./index.scss";
import Router from "./Router";

// React Query initialisation
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={CustomTheme}>
          <UnityContextProvider>
            <VideoContextProvider
              overlay={(context) => <VideoOverlay ctx={context} />}
            >
              <CssBaseline />
              <Router />
            </VideoContextProvider>
          </UnityContextProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
