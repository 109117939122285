import { UnityEvent } from "./index";
import UnityEventBase from "./UnityEventBase";

export const CursorEventType = "Cursor";

export interface CursorEvent {
  cursorType: string;
  tooltip?: string;
}

export function createCursorEvent(
  cursorType: string,
  tooltip: string
): UnityEventBase<CursorEvent> {
  return {
    type: CursorEventType,
    data: {
      cursorType,
      tooltip,
    },
  };
}

export function isCursorEvent(
  event: UnityEvent
): event is UnityEventBase<CursorEvent> {
  return event.type === CursorEventType;
}
