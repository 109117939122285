import UnityEventBase from "si-plugin-unity/events/UnityEventBase";
import { UnityEvent } from "./index";

export const VideoPlayEventType = "VideoPlay";

export interface VideoPlayEvent {
  src: string;
}

export function createVideoPlayEvent(
  src: string
): UnityEventBase<VideoPlayEvent> {
  return {
    type: VideoPlayEventType,
    data: {
      src,
    },
  };
}

export function isVideoPlayEvent(
  event: UnityEvent
): event is UnityEventBase<VideoPlayEvent> {
  return event.type === VideoPlayEventType;
}
