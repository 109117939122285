import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import Logo from "../assets/images/logo_metzeler.svg";
import { colours } from "../utilities/constants";
import Instructions from "./Instructions";
import Progress from "./Progress";

export interface InstructionsOverlayProps {
  progress: number;
  onClick: () => void;
}

export default function LoadScreen(props: InstructionsOverlayProps) {
  const { progress, onClick } = props;

  const isLoading = progress < 1;

  return (
    <Paper
      sx={(theme) => ({
        backgroundColor: colours.primary,
        position: "fixed",
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 0,
        inset: 0,
        zIndex: theme.zIndex.modal,
      })}
    >
      <Grid container width={1000} spacing={2}>
        <Grid item xs={12}>
          <Typography variant={"h2"}>Welcome to the</Typography>
          <Box padding={0.5}>
            <Box
              component={"img"}
              src={Logo}
              alt={"Metzeler logo"}
              maxWidth={{
                lg: 350,
                xs: 0.5,
              }}
            ></Box>
          </Box>
          <Typography variant={"h1"}>Heritage Experience</Typography>
        </Grid>
        <Grid item xs={12}>
          <Instructions />
        </Grid>
        <Grid item container xs={12} spacing={4} alignItems={"flex-end"}>
          <Grid item flex={1}>
            <Progress percent={progress} />
          </Grid>
          <Grid item>
            <Button
              variant={"contained"}
              sx={{
                minWidth: 200,
              }}
              disabled={isLoading}
              onClick={onClick}
            >
              {isLoading ? "Loading..." : "Enter"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
