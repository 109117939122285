// This gets its own file to avoid require-loops

export default interface UnityEventBase<T> {
  type: string;
  data: T;
}

export type SerializedUnityEvent = UnityEventBase<string>;

export function serializeEvent(
  event: UnityEventBase<unknown>
): SerializedUnityEvent {
  const { type, data } = event;

  return {
    type,
    data: JSON.stringify(data),
  };
}
