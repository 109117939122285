import { CursorEvent, CursorEventType } from "./CursorEvent";
import { PageOpenEvent, PageOpenEventType } from "./PageOpenEvent";
import UnityEventBase from "./UnityEventBase";
import { VideoPlayEvent, VideoPlayEventType } from "./VideoPlayEvent";
import { ViewpointEvent, ViewpointEventType } from "./ViewpointEvent";
import { VolumeEvent, VolumeEventType } from "./VolumeEvent";

export * from "./CursorEvent";
export * from "./UnityEventBase";

export const AllUnityEventTypes = [
  CursorEventType,
  PageOpenEventType,
  VideoPlayEventType,
  ViewpointEventType,
  VolumeEventType,
];

export type UnityEventData =
  | CursorEvent
  | PageOpenEvent
  | VideoPlayEvent
  | ViewpointEvent
  | VolumeEvent;
export type UnityEvent = UnityEventBase<UnityEventData>;

export function isUnityEvent(obj: any): obj is UnityEvent {
  if (obj.hasOwnProperty("type")) {
    return (
      typeof obj.type === "string" && AllUnityEventTypes.includes(obj.type)
    );
  }

  return false;
}
