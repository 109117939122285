import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import { colours } from "../utilities/constants";
import Instructions from "./Instructions";

export interface InstructionsOverlayProps {
  open: boolean;
  onClose: () => void;
}

export default function InstructionsOverlay(props: InstructionsOverlayProps) {
  const { open, onClose } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"lg"}
      PaperProps={{
        sx: {
          backgroundColor: colours.primary,
          padding: 4,
        },
      }}
    >
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant={"h1"}>Instructions</Typography>
          </Grid>
          <Grid item xs={12}>
            <Instructions />
          </Grid>
        </Grid>
      </DialogContent>
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
        }}
      >
        <Close sx={{ fontSize: "2.5rem", color: "#FFFFFF" }} />
      </IconButton>
    </Dialog>
  );
}
