import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import MouseClick from "../assets/click.webp";
import MouseDrag from "../assets/drag.webp";
import Keyboard from "../assets/keyboard.png";
import { colours } from "../utilities/constants";

export interface InstructionsProps {}

export default function Instructions(props: InstructionsProps) {
  return (
    <Grid
      container
      spacing={2}
      // borderTop={`1px solid ${colours.white}`}
      // borderBottom={`1px solid ${colours.white}`}
    >
      <Grid item xs={12}>
        <Divider
          sx={{
            borderBottomWidth: "thin",
            borderBottomColor: colours.white,
          }}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <Stack direction={"column"} alignItems={"center"} spacing={2}>
          <Box
            component={"img"}
            src={MouseClick}
            alt={"a mouse clicking"}
            sx={{
              objectFit: "contain",
            }}
          />
          <Typography variant={"body2"}>
            Use your mouse to click areas
            <br /> and explore the experience
          </Typography>
        </Stack>
      </Grid>
      <Grid
        item
        component={Stack}
        md={4}
        xs={12}
        display={"flex"}
        direction={"column"}
        alignItems={"center"}
        spacing={2}
      >
        <Stack
          direction={"column"}
          alignItems={"center"}
          spacing={2}
          marginTop={"10px"}
        >
          <Box
            component={"img"}
            src={MouseDrag}
            alt={"a mouse being dragged side to side"}
            sx={{
              objectFit: "contain",
            }}
          />
          <Typography variant={"body2"}>
            Click and drag your
            <br /> cursor to rotate
          </Typography>
        </Stack>
      </Grid>
      <Grid
        item
        component={Stack}
        md={4}
        xs={12}
        direction={"column"}
        alignItems={"center"}
        spacing={2}
      >
        <Stack
          direction={"column"}
          alignItems={"center"}
          spacing={2}
          marginTop={"50px"}
        >
          <Box
            component={"img"}
            src={Keyboard}
            alt={"Keyboard arrow keys"}
            height={150}
            sx={{
              objectFit: "contain",
            }}
          />
          <Typography variant={"body2"}>
            You can also use your keyboard
            <br /> arrows to move around
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Divider
          sx={{
            borderBottomWidth: "thin",
            borderBottomColor: colours.white,
          }}
        />
      </Grid>
    </Grid>
  );
}
