import { useCallback, useEffect, useRef } from "react";
import { createTimespentEvent } from "./events/TimespentEvent";
import useAnalyticsDispatch from "./useAnalyticsDispatch";
import useUnauthenticatedUser from "./useUnauthenticatedUser";

interface TimerData {
  type: string | null;
  name: string | null;
  startTime: Date;
  endTime: Date | null;
  sent: boolean;
}

export default function useAnalyticsTimer(
  type: string | null,
  name: string | null
) {
  const dispatch = useAnalyticsDispatch();
  const user = useUnauthenticatedUser();

  const data = useRef<TimerData>({
    type,
    name,
    startTime: new Date(),
    endTime: null,
    sent: false,
  });

  const Stop = useCallback(() => {
    if (data.current.endTime === null) {
      data.current.endTime = new Date();
    }
  }, []);

  const Send = useCallback(() => {
    const curr = data.current;
    if (curr.type && curr.name) {
      const end = curr.endTime ?? new Date();

      curr.sent = true;
      dispatch(
        createTimespentEvent(user, curr.type, curr.name, curr.startTime, end)
      );
    }
  }, [user, dispatch]);

  const StopAndSend = useCallback(
    (forceSend = false) => {
      Stop();
      if (!data.current.sent || forceSend) {
        Send();
      }
    },
    [Stop, Send]
  );

  const Restart = useCallback(() => {
    data.current.startTime = new Date();
    data.current.endTime = null;
    data.current.sent = false;
  }, []);

  useEffect(() => {
    if (type !== data.current.type || name !== data.current.name) {
      StopAndSend();
      data.current.type = type;
      data.current.name = name;
      Restart();
    }
  }, [name, type, StopAndSend, Restart]);

  // Send events when the page is closed
  useEffect(() => {
    const callback = () => {
      StopAndSend();
    };

    window.addEventListener("beforeunload", callback);
    return () => {
      window.removeEventListener("beforeunload", callback);
    };
  });

  // Send events when the component is unmounted
  useEffect(
    () => () => {
      if (!data.current.sent) {
        StopAndSend();
      }
    },
    [StopAndSend]
  );

  return {
    Stop,
    Send,
    StopAndSend,
    Restart,
  };
}
