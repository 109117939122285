import { ElementOf } from "si-plugin-utils";

export const UnityService = "WebGLService";
export const UnityEventReceiver = "ReceiveEvent";
export const UnityEventType = "unity-event";

export const UnityEvents = {
  CursorEvent: "CursorEvent",
} as const;

export const UnityStates = {
  NotLoaded: "NotLoaded",
  ScriptLoading: "ScriptLoading",
  ScriptReady: "ScriptReady",
  Loading: "Loading",
  Ready: "Ready",
  Playing: "Playing",
} as const;

export type UnityState = ElementOf<typeof UnityStates>;

export const LoadingStates: UnityState[] = [
  UnityStates.ScriptLoading,
  UnityStates.ScriptReady,
  UnityStates.Loading,
];
