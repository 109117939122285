import UAParser from "ua-parser-js";
import AnalyticsEventBase from "./AnalyticsEventBase";

export const SessionEventName = "Session";

export interface SessionEventData {
  name: string;
  version: string;
  agent: string;
}
export type SessionEvent = AnalyticsEventBase<
  typeof SessionEventName,
  SessionEventData
>;

export function createSessionEvent(
  user_id: string | null,
  session_id: string,
): SessionEvent {
  const uaParser = new UAParser();
  const browser = uaParser.getBrowser();

  return {
    name: SessionEventName,
    user_id,
    session_id,
    data: {
      name: browser.name ?? "UNKNOWN",
      agent: uaParser.getUA(),
      version: browser.version ?? "UNKNOWN",
    },
  };
}
