import { UnityEvent } from "./index";
import UnityEventBase from "./UnityEventBase";

export const ViewpointEventType = "ViewpointEvent";

export interface ViewpointEvent {
  id: string;
  active: boolean;
}

export function createViewpointEvent(
  id: string,
  active: boolean
): UnityEventBase<ViewpointEvent> {
  return {
    type: ViewpointEventType,
    data: {
      id,
      active,
    },
  };
}

export function isViewpointEvent(
  event: UnityEvent
): event is UnityEventBase<ViewpointEvent> {
  return event.type === ViewpointEventType;
}
