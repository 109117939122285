import { Box, SxProps, Theme } from "@mui/material";
import React, { ReactNode, useState } from "react";
import { combineSxProps } from "si-plugin-utils";
import UnityCanvas, { UnityCanvasProps } from "./UnityCanvas";
import UnityLoadScreen from "./UnityLoadScreen";

export interface UnityPlayerProps extends UnityCanvasProps {
  slotProps?: {
    canvasSx?: SxProps<Theme>;
  };
  loadScreen?:
    | ReactNode
    | ReactNode[]
    | ((progress: number) => ReactNode | ReactNode[]);
  forceShowLoadScreen?: boolean;
  children?: ReactNode | ReactNode[];
}

const UnityPlayer = (props: UnityPlayerProps): JSX.Element => {
  const {
    loadScreen = (p) => <UnityLoadScreen loadProgress={p} />,
    forceShowLoadScreen,
    slotProps = {},
    children,
    sx,
    onLoadProgress,
    ...canvasProps
  } = props;
  const { canvasSx } = slotProps;

  const [loadProgress, setLoadProgress] = useState(0);

  const showLoadScreen = forceShowLoadScreen || loadProgress < 1;

  const handleLoadProgress = (value: number) => {
    setLoadProgress(value);
    onLoadProgress?.(value);
  };

  return (
    <Box
      sx={combineSxProps(
        {
          position: "relative",
          display: "flex",
          flexFlow: "column",
          flex: "1 1 auto",
          height: 0,
        },
        sx
      )}
    >
      {showLoadScreen &&
        (typeof loadScreen === "function"
          ? loadScreen(loadProgress)
          : loadScreen)}
      <UnityCanvas
        key={canvasProps.buildUrl}
        {...canvasProps}
        sx={canvasSx}
        onLoadProgress={handleLoadProgress}
      />
      {children}
    </Box>
  );
};
export default UnityPlayer;
