import { UnityEvent } from "./index";
import UnityEventBase from "./UnityEventBase";

export const VolumeEventType = "VolumeEvent";

export interface VolumeEvent {
  value: number;
}

export function createVolumeEvent(value: number): UnityEventBase<VolumeEvent> {
  return {
    type: VolumeEventType,
    data: {
      value,
    },
  };
}

export function isVolumeEvent(
  event: UnityEvent
): event is UnityEventBase<VolumeEvent> {
  return event.type === VolumeEventType;
}
